
	frappe.templates['sort_selector'] = `<div class="sort-selector">
	<div class="btn-group">
		<button class="btn btn-default btn-xs btn-order"
			data-value="{{ sort_order }}"
			title="{{ sort_order==="desc" ? __("descending") : __("ascending") }}">
			<span class="sort-order">
				<svg class="icon icon-sm">
					<use href="#icon-{{ sort_order==="desc" ? "sort-descending" : "sort-ascending" }}"></use>
				</svg>
			</span>
		</button>
		<button type="button" class="btn btn-default btn-xs sort-selector-button" data-toggle="dropdown">
			<span class="dropdown-text">{{ __(sort_by_label) }}</span>
			<div class="dropdown-menu dropdown-menu-right">
				{% for value in options %}
					<a class="dropdown-item option" data-value="{{ value.fieldname }}">
						{{ __(value.label) }}
					</a>
				{% endfor %}
				</div>
		</button>
	</div>
</div>
`;
